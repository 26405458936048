import axios from 'axios';
import { auth, signOut } from './firebase';

// Create the custom Axios instance
const customAxios = axios.create({
    withCredentials: true,
});

// Request interceptor to add the ID token and session ID
customAxios.interceptors.request.use(
    async (config) => {
        try {
            const idToken = await auth.currentUser?.getIdToken();
            const sessionId = localStorage.getItem('sessionId');

            if (idToken) {
                config.headers.Authorization = `Bearer ${idToken}`;
            }
            if (sessionId) {
                config.headers['x-session-id'] = sessionId;
            }
        } catch (error) {
            console.error('Error fetching ID token:', error);
            // Optionally handle the error, e.g., redirect to login if token fetch fails
        }

        if (!config.headers['Content-Type']) {
            config.headers['Content-Type'] = 'application/json';
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle 401 Unauthorized responses
customAxios.interceptors.response.use(
    response => response, // Pass through any successful response
    async (error) => {
        if (error.response && error.response.status === 401) {
            //console.log("Received 401 Unauthorized response");

            try {
                // Sign out the user from Firebase
                await signOut(auth);
            } catch (signOutError) {
                //console.error('Error signing out:', signOutError);
            }

            // Optionally clear session ID from localStorage
            localStorage.removeItem('sessionId');

            // Trigger a popup or modal here
            alert("Session expired. Please log in again."); // Simplified with an alert, you can use a custom modal instead.
        }

        return Promise.reject(error);
    }
);

export default customAxios;
