import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import customAxios from '../customAxios';
import useAuth from '../hooks/useAuth';

const useFetchAuthorizedMlKits = () => {
    const [authorizedMlKits, setAuthorizedMlKits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null); // Local state for user
    const { userRole } = useAuth();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchMlKits = async () => {
            try {
                setLoading(true);
                setError(null);

                // Fetch all ML kits
                const mlKitsResponse = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/mlKits`);
                const mlKits = mlKitsResponse.data;

                let filteredMlKits = [];

                if (userRole === 'admin') {
                    // Admins see all kits
                    filteredMlKits = mlKits;
                } else if (userRole === 'instructor') {
                    // Instructors see all non-private ML kits
                    filteredMlKits = mlKits.filter(mlKit => !mlKit.isPrivate);
                } else if (user) {
                    // Regular users see only authorized ML kits
                    const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/userAuthorizations/${user.uid}`);
                    const authorizedProducts = response.data.mlKits || {};

                    filteredMlKits = mlKits.filter(mlKit => authorizedProducts[mlKit.id]);
                }

                setAuthorizedMlKits(filteredMlKits);
            } catch (err) {
                console.error('Error fetching ML Kits:', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (user && userRole) {
            fetchMlKits();
        }
    }, [user, userRole]);

    return { authorizedMlKits, loading, error };
};

export default useFetchAuthorizedMlKits;
