import React from 'react';
import { motion } from 'framer-motion';
import './ThreeStepDiagram.css';

const steps = [
    {
        title: 'Step 1: Register with Google',
        description: 'Sign up for StatLeap easily using your Google account. No additional details or password required.',
        icon: 'fas fa-user-plus',
    },
    {
        title: 'Step 2: Enter Bundle Code or Choose Plan',
        description: 'Enter a bundle code from an email or instructor, or choose a plan directly from the bundles page.',
        icon: 'fas fa-key',
    },
    {
        title: 'Step 3: Access Your Bundle',
        description: 'Enjoy instant access to powerful data analytics tools after purchasing or redeeming your bundle.',
        icon: 'fas fa-box-open',
    }
];

const ThreeStepDiagram = () => {
    return (
        <div className="three-step-diagram-container">
            <h2 className="diagram-title">How to Get Started</h2>
            <div className="steps-grid">
                {steps.map((step, index) => (
                    <motion.div
                        key={index}
                        className="step-card"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.3 }}
                    >
                        <i className={`${step.icon} step-icon`}></i>
                        <h3 className="step-title">{step.title}</h3>
                        <p className="step-description">{step.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default ThreeStepDiagram;
