import React, { useState } from 'react';
import './BundleList.css';
import useAuth from '../hooks/useAuth'; // Hook to get authentication info

const BundleList = ({ bundles, onSelect, onCopyCode, onCopyLink, onEdit }) => {
    const { userRole } = useAuth(); // Fetch the logged-in user role
    const [showFreeTrials, setShowFreeTrials] = useState(false);

    // Filter bundles based on admin privileges and free trial visibility
    const filteredBundles = bundles.filter((bundle) => {
        if (showFreeTrials) {
            return bundle.freeTrial; // Show only free trials if the free trial tab is active
        }
        return !bundle.freeTrial; // Exclude free trials if not showing them
    });

    return (
        <div className="bundles-list">
            <h2>Your Bundles</h2>

            {/* Admin Tab for Viewing Free Trials */}
            {userRole === 'admin' && (
                <div className="tab-buttons">
                    <button
                        className={`tab-button ${!showFreeTrials ? 'active' : ''}`}
                        onClick={() => setShowFreeTrials(false)}
                    >
                        Standard Bundles
                    </button>
                    <button
                        className={`tab-button ${showFreeTrials ? 'active' : ''}`}
                        onClick={() => setShowFreeTrials(true)}
                    >
                        Free Trial Bundles
                    </button>
                </div>
            )}

            {filteredBundles.length === 0 ? (
                <p>No bundles found.</p>
            ) : (
                filteredBundles.map((bundle) => (
                    <div
                        key={bundle.id}
                        className="bundle-item"
                        onClick={() => onSelect(bundle)} // Clicking on the bundle opens the details page
                    >
                        <div className="bundle-info">
                            <h4>{bundle.name}</h4>
                            <p>{bundle.description}</p>
                            <p>Code: {bundle.code}</p>
                        </div>
                        <div className="bundle-actions" onClick={(e) => e.stopPropagation()}> {/* Prevent click event from bubbling up */}
                            <button onClick={() => onCopyCode(bundle.code)} className="copy-button">
                                Copy Code
                            </button>
                            <button
                                onClick={() => onCopyLink(`${window.location.origin}/purchase-bundle?code=${bundle.code}`)}
                                className="copy-button"
                            >
                                Copy Link
                            </button>
                            <button onClick={() => onEdit(bundle)} className="edit-button">
                                Edit Bundle
                            </button>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default BundleList;
