import React, { useState, useEffect, useRef } from 'react';
import customAxios from '../customAxios';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import './ToolkitManagement.css';

const ToolkitManagement = () => {
    const [mlKits, setMlKits] = useState([]);
    const [newMlKit, setNewMlKit] = useState({
        id: null,
        imageUrl: '',
        toolName: '',
        description: '',
        shinyAppsUrl: '',
        priceAmount: '',
        isPrivate: false,
        priority: null,
    });
    const [imageFile, setImageFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('gallery');

    const formRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        fetchMlKits();
    }, []);

    const fetchMlKits = async () => {
        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/mlKits`);
            const sortedMlKits = response.data.sort((a, b) => b.priority - a.priority);
            const mlKitsWithPrices = await Promise.all(sortedMlKits.map(async (kit) => {
                const price = await fetchPrice(kit.priceId);
                return { ...kit, priceAmount: price.unit_amount ? price.unit_amount / 100 : 0 };
            }));
            setMlKits(mlKitsWithPrices);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchPrice = async (priceId) => {
        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/prices/${priceId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching price with ID ${priceId}:`, error);
            return { unit_amount: 0 };
        }
    };

    const handleFileChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleMlKitChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewMlKit(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleUploadAndSaveMlKit = async () => {
        if (!imageFile && !newMlKit.imageUrl) {
            setError(new Error("No image selected or URL provided"));
            return;
        }

        setUploading(true);

        if (imageFile) {
            const storageRef = ref(storage, `mlkits/${imageFile.name}`);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error('Upload failed:', error);
                    setError(error);
                    setUploading(false);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await saveMlKit(downloadURL);
                    } catch (error) {
                        console.error('Error saving ML kit:', error);
                    } finally {
                        setUploading(false);
                    }
                }
            );
        } else {
            await saveMlKit(newMlKit.imageUrl);
            setUploading(false);
        }
    };

    const saveMlKit = async (imageUrl) => {
        try {
            const priceAmount = parseFloat(newMlKit.priceAmount) * 100; // Convert to cents
            if (isNaN(priceAmount)) {
                throw new Error('Invalid price amount');
            }

            const priority = newMlKit.priority || calculateNextPriority(); // Ensure priority is set

            const mlKitData = {
                ...(imageUrl && { imageUrl }), // Include only if imageUrl exists
                ...(newMlKit.toolName && { toolName: newMlKit.toolName }),
                ...(newMlKit.description && { description: newMlKit.description }),
                ...(newMlKit.shinyAppsUrl && { shinyAppsUrl: newMlKit.shinyAppsUrl }),
                priceAmount,  // Always include priceAmount
                priority,     // Always include priority
                isPrivate: newMlKit.isPrivate ?? false, // Default to false if not set
            };

            if (newMlKit.id) {
                // Update existing ML Kit
                if (Object.keys(mlKitData).length > 0) {  // Ensure there's data to update
                    await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/mlKits/${newMlKit.id}`, mlKitData);
                } else {
                    console.error('No fields to update');
                    return;
                }
            } else {
                // Create new ML Kit
                await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/mlKits`, mlKitData);
            }

            resetForm();
            fetchMlKits();
            tableRef.current.scrollIntoView({ behavior: 'auto' });
        } catch (error) {
            console.error(`Error saving ML kit: ${error.message}`);
        }
    };

    const handleMlKitEdit = (kit) => {
        setNewMlKit({
            id: kit.id,
            imageUrl: kit.imageUrl,
            toolName: kit.toolName,
            description: kit.description,
            shinyAppsUrl: kit.shinyAppsUrl,
            priceAmount: (kit.priceAmount || 0).toString(),
            isPrivate: kit.isPrivate,
            priority: kit.priority, // Edit priority
        });
        setImageFile(null); // Reset file input
        formRef.current.scrollIntoView({ behavior: 'auto' }); // Scroll to form
    };

    const handleMlKitDelete = async (id) => {
        try {
            await customAxios.delete(`${process.env.REACT_APP_API_BASE_URL}/mlKits/${id}`);
            setMlKits(mlKits.filter(kit => kit.id !== id));
        } catch (err) {
            setError(err);
        }
    };

    const calculateNextPriority = () => {
        if (mlKits.length === 0) return 1;
        const highestPriority = Math.max(...mlKits.map(kit => kit.priority || 0));
        return highestPriority + 1;
    };

    const resetForm = () => {
        setNewMlKit({
            id: null,
            imageUrl: '',
            toolName: '',
            description: '',
            shinyAppsUrl: '',
            priceAmount: '',
            isPrivate: false,
            priority: null,
        });
        setImageFile(null);
        setUploadProgress(0);
        setUploading(false);
    };

    const handleCancelEdit = () => {
        resetForm();
        tableRef.current.scrollIntoView({ behavior: 'auto' });
    };

    const moveMlKitUp = (index) => {
        if (index === 0) return; // Already at the top
        const reorderedKits = [...mlKits];
        const [kit] = reorderedKits.splice(index, 1);
        reorderedKits.splice(index - 1, 0, kit);
        updateMlKitPriorities(reorderedKits);
    };

    const moveMlKitDown = (index) => {
        if (index === mlKits.length - 1) return; // Already at the bottom
        const reorderedKits = [...mlKits];
        const [kit] = reorderedKits.splice(index, 1);
        reorderedKits.splice(index + 1, 0, kit);
        updateMlKitPriorities(reorderedKits);
    };

    const updateMlKitPriorities = (reorderedKits) => {
        reorderedKits.forEach((kit, index) => {
            kit.priority = reorderedKits.length - index;
        });
        setMlKits(reorderedKits);

        // Save the updated priorities to the database
        reorderedKits.forEach(async (kit) => {
            await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/mlKits/${kit.id}`, { priority: kit.priority });
        });
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="toolkit-management">
            <h2 className="toolkit-management-title" ref={formRef}>ML Toolkit Management</h2>

            {/* ML Kit Form */}
            <div className="mlkit-form">
                <div className="form-group">
                    <label htmlFor="imageFile">Upload Image</label>
                    <input type="file" id="imageFile" accept="image/*" onChange={handleFileChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="toolName">Tool Name</label>
                    <input type="text" id="toolName" name="toolName" placeholder="Enter tool name" value={newMlKit.toolName} onChange={handleMlKitChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea id="description" name="description" placeholder="Enter description" value={newMlKit.description} onChange={handleMlKitChange} rows={5} style={{ resize: 'none' }} />
                </div>

                <div className="form-group">
                    <label htmlFor="shinyAppsUrl">Shiny Apps URL</label>
                    <input type="text" id="shinyAppsUrl" name="shinyAppsUrl" placeholder="Enter Shiny Apps URL" value={newMlKit.shinyAppsUrl} onChange={handleMlKitChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="priceAmount">Base Price (in USD)</label>
                    <input type="text" id="priceAmount" name="priceAmount" placeholder="Enter base price" value={newMlKit.priceAmount} onChange={handleMlKitChange} />
                </div>

                <div className="form-group checkbox-group">
                    <label>
                        <input type="checkbox" name="isPrivate" checked={newMlKit.isPrivate} onChange={handleMlKitChange} /> Private
                    </label>
                </div>

                {newMlKit.id ? (
                    <>
                        <button onClick={handleUploadAndSaveMlKit} disabled={uploading}>
                            {uploading ? `Updating... (${uploadProgress}%)` : 'Update ML Kit'}
                        </button>
                        <button onClick={handleCancelEdit} disabled={uploading}>Cancel Edit</button>
                    </>
                ) : (
                    <button onClick={handleUploadAndSaveMlKit} disabled={uploading}>
                        {uploading ? `Uploading... (${uploadProgress}%)` : 'Add ML Kit'}
                    </button>
                )}
            </div>

            {/* View Mode Toggle */}
            <div className="view-toggle">
                <button onClick={() => setViewMode('gallery')} className={viewMode === 'gallery' ? 'active' : ''}>
                    Gallery View
                </button>
                <button onClick={() => setViewMode('list')} className={viewMode === 'list' ? 'active' : ''}>
                    List View
                </button>
            </div>

            {/* Conditionally Render ML Kit Table */}
            {viewMode === 'list' ? (
                <table className="mlkits-table" ref={tableRef}>
                    <thead>
                        <tr>
                            <th>Tool Name</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Actions</th>
                            <th>Reorder</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mlKits.map((kit, index) => (
                            <tr key={kit.id}>
                                <td>{kit.toolName}</td>
                                <td>{kit.description.length > 50 ? `${kit.description.slice(0, 50)}...` : kit.description}</td>
                                <td>${kit.priceAmount.toFixed(2)}</td>
                                <td className="actions-column">
                                    <button onClick={() => window.open(kit.shinyAppsUrl, '_blank')} className="open-toolkit-button">Open Tool</button>
                                    <button onClick={() => handleMlKitEdit(kit)}>Edit</button>
                                    <button onClick={() => handleMlKitDelete(kit.id)}>Delete</button>
                                </td>
                                <td>
                                    <button onClick={() => moveMlKitUp(index)} disabled={index === 0}>Move Up</button>
                                    <button onClick={() => moveMlKitDown(index)} disabled={index === mlKits.length - 1}>Move Down</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="mlkits-gallery">
                    {mlKits.map(kit => (
                        <div className="mlkit-item" key={kit.id}>
                            <img src={kit.imageUrl} alt={kit.toolName} />
                            <h4>{kit.toolName}</h4>
                            <p>Price: ${kit.priceAmount.toFixed(2)}</p>
                            <p>{kit.description}</p>
                            <div className="actions">
                                <button onClick={() => window.open(kit.shinyAppsUrl, '_blank')} className="open-toolkit-button">Open Tool</button>
                                <button onClick={() => handleMlKitEdit(kit)}>Edit</button>
                                <button onClick={() => handleMlKitDelete(kit.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ToolkitManagement;
