import { useState } from 'react';
import customAxios from '../customAxios';

const useUpdateUserRole = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateUserRole = async (userId, newRole) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}/role`, { role: newRole });
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { updateUserRole, loading, error, success };
};

export default useUpdateUserRole;
