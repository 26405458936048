import React, { useState, useEffect, useRef } from 'react';
import customAxios from '../customAxios';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import './CaseStudyManagement.css'; // CSS file

const CaseStudyManagement = () => {
    const [caseStudies, setCaseStudies] = useState([]);
    const [newCaseStudy, setNewCaseStudy] = useState({
        id: null,
        name: '',
        priceAmount: '',
        isPrivate: false,
        description: '',
        pdfDocuments: [],
        supplementalDocuments: [],
        imageUrl: '',
        tags: [],
        priority: null,
        priceId: null,
    });
    const [pdfFiles, setPdfFiles] = useState([]);
    const [supplementalFiles, setSupplementalFiles] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tagsInput, setTagsInput] = useState(''); // Tags input state
    const formRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        fetchCaseStudies();
    }, []);

    const fetchCaseStudies = async () => {
        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/case-studies`);
            const sortedCaseStudies = response.data.sort((a, b) => a.priority - b.priority);
            setCaseStudies(sortedCaseStudies);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (e, setFiles) => {
        setFiles([...e.target.files]);
    };

    const handleImageFileChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleCaseStudyChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewCaseStudy(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleTagsChange = (e) => {
        setTagsInput(e.target.value);
    };

    const handleTagKeyPress = (e) => {
        if (e.key === 'Enter' && tagsInput.trim() !== '') {
            e.preventDefault();
            setNewCaseStudy(prev => ({
                ...prev,
                tags: [...prev.tags, tagsInput.trim()],
            }));
            setTagsInput('');
        }
    };

    const handleRemoveTag = (tagToRemove) => {
        setNewCaseStudy(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToRemove),
        }));
    };

    const handleRemoveFile = (fileUrl, fileType) => {
        setNewCaseStudy(prev => ({
            ...prev,
            [fileType]: prev[fileType].filter(url => url !== fileUrl),
        }));
    };

    const handleUploadAndSaveCaseStudy = async () => {
        if (!newCaseStudy.id && pdfFiles.length === 0 && supplementalFiles.length === 0 && !imageFile) {
            setError(new Error("No files or image provided"));
            return;
        }

        setUploading(true);
        let pdfUrls = newCaseStudy.pdfDocuments;
        let supplementalUrls = newCaseStudy.supplementalDocuments;
        let imageUrl = newCaseStudy.imageUrl;

        if (pdfFiles.length > 0) {
            pdfUrls = [...pdfUrls, ...await uploadFiles(pdfFiles, 'pdfDocuments')];
        }

        if (supplementalFiles.length > 0) {
            supplementalUrls = [...supplementalUrls, ...await uploadFiles(supplementalFiles, 'supplementalDocuments')];
        }

        if (imageFile) {
            imageUrl = await uploadImage(imageFile);
        }

        await saveCaseStudy(pdfUrls, supplementalUrls, imageUrl);
        setUploading(false);
    };

    const uploadFiles = async (files, folder) => {
        const uploadedUrls = [];
        const uploadProgress = [];

        for (const [index, file] of files.entries()) {
            const storageRef = ref(storage, `${folder}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            await new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        uploadProgress[index] = progress;
                        setUploadProgress([...uploadProgress]);
                    },
                    (error) => {
                        setError(error);
                        reject(error);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        uploadedUrls.push(downloadURL);
                        resolve();
                    }
                );
            });
        }

        return uploadedUrls;
    };

    const uploadImage = async (imageFile) => {
        const storageRef = ref(storage, `caseStudies/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    setError(error);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                }
            );
        });
    };

    const saveCaseStudy = async (pdfUrls, supplementalUrls, imageUrl) => {
        try {
            const priceAmount = parseFloat(newCaseStudy.priceAmount) * 100;
            if (isNaN(priceAmount)) {
                throw new Error('Invalid price amount');
            }

            const caseStudyData = {
                ...newCaseStudy,
                pdfDocuments: pdfUrls,
                supplementalDocuments: supplementalUrls,
                imageUrl,
                priceAmount,
                priority: newCaseStudy.priority || calculateNextPriority(),
            };

            if (newCaseStudy.id) {
                await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/case-studies/${newCaseStudy.id}`, caseStudyData);
            } else {
                await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/case-studies`, caseStudyData);
            }

            resetForm();
            fetchCaseStudies();
        } catch (error) {
            setError(error);
        }
    };

    const calculateNextPriority = () => {
        if (caseStudies.length === 0) return 0;
        return caseStudies.length;
    };

    const handleCaseStudyEdit = (caseStudy) => {
        setNewCaseStudy({
            id: caseStudy.id,
            name: caseStudy.name,
            priceAmount: (caseStudy.priceAmount / 100).toString(),
            isPrivate: caseStudy.isPrivate,
            description: caseStudy.description || '',
            priority: caseStudy.priority,
            pdfDocuments: caseStudy.pdfDocuments || [],
            supplementalDocuments: caseStudy.supplementalDocuments || [],
            imageUrl: caseStudy.imageUrl || '',
            tags: caseStudy.tags || [], // Handle missing tags
            priceId: caseStudy.priceId,
        });
        setPdfFiles([]);
        setSupplementalFiles([]);
        setImageFile(null);
        formRef.current.scrollIntoView({ behavior: 'auto' });
    };

    const handleCaseStudyDelete = async (id) => {
        try {
            await customAxios.delete(`${process.env.REACT_APP_API_BASE_URL}/case-studies/${id}`);
            setCaseStudies(caseStudies.filter(caseStudy => caseStudy.id !== id));
        } catch (err) {
            setError(err);
        }
    };

    const resetForm = () => {
        setNewCaseStudy({
            id: null,
            name: '',
            priceAmount: '',
            isPrivate: false,
            description: '',
            priority: null,
            pdfDocuments: [],
            supplementalDocuments: [],
            imageUrl: '',
            tags: [],
            priceId: null,
        });
        setPdfFiles([]);
        setSupplementalFiles([]);
        setImageFile(null);
        setUploadProgress([]);
    };

    const moveCaseStudy = (index, direction) => {
        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= caseStudies.length) return;
        const reorderedStudies = [...caseStudies];
        const [study] = reorderedStudies.splice(index, 1);
        reorderedStudies.splice(newIndex, 0, study);
        updateCaseStudyPriorities(reorderedStudies);
    };

    const updateCaseStudyPriorities = (reorderedStudies) => {
        reorderedStudies.forEach((study, index) => {
            study.priority = index;
        });
        setCaseStudies(reorderedStudies);

        reorderedStudies.forEach(async (study) => {
            await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/case-studies/${study.id}`, { priority: study.priority });
        });
    };

    const handleCancelEdit = () => {
        resetForm();
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="case-study-management">
            <h2 className="case-study-management-title" ref={formRef}>Case Study Management</h2>

            {/* Form for adding/editing case studies */}
            <div className="case-study-form">
                <div className="form-group">
                    <label htmlFor="name">Case Study Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter case study name"
                        value={newCaseStudy.name}
                        onChange={handleCaseStudyChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        placeholder="Enter a short description"
                        value={newCaseStudy.description}
                        onChange={handleCaseStudyChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="priceAmount">Base Price (in USD)</label>
                    <input
                        type="text"
                        id="priceAmount"
                        name="priceAmount"
                        placeholder="Enter base price"
                        value={newCaseStudy.priceAmount}
                        onChange={handleCaseStudyChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="pdfFiles">Upload PDFs</label>
                    <input
                        type="file"
                        id="pdfFiles"
                        accept="application/pdf"
                        multiple
                        onChange={(e) => handleFileChange(e, setPdfFiles)}
                    />
                    {pdfFiles.map((file, index) => (
                        <div key={index}>
                            <span>{file.name}</span>
                            <progress value={uploadProgress[index]} max="100">{uploadProgress[index]}%</progress>
                        </div>
                    ))}
                </div>
                {newCaseStudy.pdfDocuments.length > 0 && (
                    <div>
                        <h4>Existing PDF Documents</h4>
                        <ul>
                            {newCaseStudy.pdfDocuments.map((pdfUrl, index) => (
                                <li key={index}>
                                    <span>{pdfUrl}</span>
                                    <button className="remove-button" onClick={() => handleRemoveFile(pdfUrl, 'pdfDocuments')}>Remove</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="supplementalFiles">Upload Supplemental Files</label>
                    <input
                        type="file"
                        id="supplementalFiles"
                        multiple
                        onChange={(e) => handleFileChange(e, setSupplementalFiles)}
                    />
                    {supplementalFiles.map((file, index) => (
                        <div key={index}>
                            <span>{file.name}</span>
                            <progress value={uploadProgress[index]} max="100">{uploadProgress[index]}%</progress>
                        </div>
                    ))}
                </div>
                {newCaseStudy.supplementalDocuments.length > 0 && (
                    <div>
                        <h4>Existing Supplemental Documents</h4>
                        <ul>
                            {newCaseStudy.supplementalDocuments.map((supplementalUrl, index) => (
                                <li key={index}>
                                    <span>{supplementalUrl}</span>
                                    <button className="remove-button" onClick={() => handleRemoveFile(supplementalUrl, 'supplementalDocuments')}>Remove</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="imageFile">Upload Image</label>
                    <input type="file" id="imageFile" accept="image/*" onChange={handleImageFileChange} />
                </div>
                {newCaseStudy.imageUrl && (
                    <div>
                        <h4>Existing Image</h4>
                        <img src={newCaseStudy.imageUrl} alt="Case Study" style={{ maxWidth: '200px' }} />
                    </div>
                )}
                <div className="form-group">
                    <label>Tags</label>
                    <input
                        type="text"
                        value={tagsInput}
                        onChange={handleTagsChange}
                        onKeyPress={handleTagKeyPress}
                        placeholder="Enter a tag and press Enter"
                    />
                    <div className="tags-list">
                        {newCaseStudy.tags.map((tag, index) => (
                            <span key={index} className="tag">
                                {tag} <button onClick={() => handleRemoveTag(tag)}>x</button>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="form-group checkbox-group">
                    <label>
                        <input
                            type="checkbox"
                            name="isPrivate"
                            checked={newCaseStudy.isPrivate}
                            onChange={handleCaseStudyChange}
                        />
                        Private
                    </label>
                </div>
                {newCaseStudy.id ? (
                    <>
                        <button onClick={handleUploadAndSaveCaseStudy} disabled={uploading}>
                            {uploading ? `Updating...` : 'Update Case Study'}
                        </button>
                        <button onClick={handleCancelEdit} disabled={uploading}>Cancel Edit</button>
                    </>
                ) : (
                    <button onClick={handleUploadAndSaveCaseStudy} disabled={uploading}>
                        {uploading ? `Uploading...` : 'Add Case Study'}
                    </button>
                )}
            </div>

            {/* Table of case studies */}
            <table className="case-studies-table" ref={tableRef}>
                <thead>
                    <tr>
                        <th>Case Study Name</th>
                        <th>Price</th>
                        <th>Tags</th>
                        <th>Actions</th>
                        <th>Reorder</th>
                    </tr>
                </thead>
                <tbody>
                    {caseStudies.map((caseStudy, index) => (
                        <tr key={caseStudy.id}>
                            <td>{caseStudy.name}</td>
                            <td>${(caseStudy.priceAmount / 100).toFixed(2)}</td>
                            <td>{(caseStudy.tags || []).join(', ')}</td>
                            <td className="actions-column">
                                <button onClick={() => handleCaseStudyEdit(caseStudy)}>Edit</button>
                                <button onClick={() => handleCaseStudyDelete(caseStudy.id)}>Delete</button>
                            </td>
                            <td>
                                <button onClick={() => moveCaseStudy(index, -1)} disabled={index === 0}>Move Up</button>
                                <button onClick={() => moveCaseStudy(index, 1)} disabled={index === caseStudies.length - 1}>Move Down</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CaseStudyManagement;
