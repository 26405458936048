import React, { useState } from 'react';
import './Resources.css';

const Resources = () => {
    const [activeTab, setActiveTab] = useState('readings');
    const [selectedTutorial, setSelectedTutorial] = useState(null); // For handling individual tutorial view

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setSelectedTutorial(null); // Reset tutorial view if coming from the tutorial page
    };

    const handleTutorialClick = (tutorial) => {
        setSelectedTutorial(tutorial); // Set the clicked tutorial to show detailed view
    };

    const handleBackToTutorials = () => {
        setSelectedTutorial(null); // Go back to tutorials list
    };

    // Readings section
    const readings = [
        {
            title: 'An Introduction to Statistical Learning',
            description: 'An Introduction to Statistical Learning provides a comprehensive and accessible introduction to modern data analysis techniques, focusing on key concepts in machine learning, statistical modeling, and data mining. It is ideal for beginners and practitioners looking to apply these methods in various fields.',
            link: 'https://www.statlearning.com/'
        },
        {
            title: 'R for Marketing Research and Analytics',
            description: 'R for Marketing Research and Analytics is a practical guide that demonstrates how to use R for data analysis in marketing, covering essential techniques such as segmentation, conjoint analysis, and predictive modeling, tailored for marketing professionals and researchers.',
            link: 'https://r-marketing.r-forge.r-project.org/index.html'
        },
        {
            title: 'Data Analytics and Decision Making',
            description: 'Data Analytics and Decision Making is an open textbook that focuses on three core areas: data acquisition, data processing, and decision-making models. It equips students with the skills to gather relevant data, apply quantitative techniques like statistics and machine learning, and use predictive or prescriptive models to make informed, impactful decisions for businesses or projects.',
            link: 'https://ecampusontario.pressbooks.pub/dataanalyticsvls1/'
        }
    ];

    // Datasets section
    const datasets = [
        {
            title: 'Data.gov',
            description: 'Data.gov is the U.S. government\'s open data portal, providing free access to a vast collection of datasets from federal, state, and local agencies.It aims to promote transparency, innovation, and public engagement by offering data across a wide range of topics, including healthcare, education, and the environment.',
            link: 'https://catalog.data.gov/dataset/'
        },
        {
            title: 'UCI Machine Learning Repository',
            description: 'The UCI Machine Learning Repository, hosted by the University of California, Irvine, provides a collection of databases, domain theories, and datasets widely used for empirical research in machine learning and data mining. It is an essential resource for researchers and practitioners, offering diverse datasets for testing algorithms and developing models across various fields.',
            link: 'https://archive.ics.uci.edu/datasets'
        },
        {
            title: 'World Bank Data Catalog',
            description: 'The World Bank Data Catalog provides access to a vast collection of datasets on global development, economics, and social issues. It includes open data on various topics such as poverty, education, health, and infrastructure, allowing researchers, policymakers, and the public to explore and analyze trends that impact countries around the world. This resource supports evidence-based decision-making and promotes global transparency. ',
            link: 'https://data.worldbank.org/'
        },
        {
            title: 'Google Dataset Search',
            description: 'Google Dataset Search is a specialized search engine that helps users discover datasets across the web. It indexes datasets from various repositories, covering a wide range of topics such as government data, scientific research, and public data archives. The tool is designed to make data more accessible for researchers, data scientists, and the general public by providing a central platform to locate datasets from multiple sources quickly and efficiently.',
            link: 'https://datasetsearch.research.google.com/'
        }
    ];

    // Tutorials section (coming soon)
    const tutorials = [
        {
            title: 'Tutorial 1: Coming Soon',
            videoUrl: 'coming_soon'
        },
        {
            title: 'Tutorial 2: Coming Soon',
            videoUrl: 'coming_soon'
        }
    ];

    return (
        <div className="resources-page">
            <h2 className="resources-title">Resources</h2>

            {/* Tabs for navigation */}
            <div className="resources-buttons">
                <button
                    className={`resources-btn ${activeTab === 'readings' ? 'active' : ''}`}
                    onClick={() => handleTabClick('readings')}
                >
                    Readings
                </button>
                <button
                    className={`resources-btn ${activeTab === 'datasets' ? 'active' : ''}`}
                    onClick={() => handleTabClick('datasets')}
                >
                    Sample Datasets
                </button>
                <button
                    className={`resources-btn ${activeTab === 'tutorials' ? 'active' : ''}`}
                    onClick={() => handleTabClick('tutorials')}
                >
                    Tutorials
                </button>
            </div>

            <div className="resources-content">
                {activeTab === 'readings' && (
                    <div className="resources-section">
                        <h3>Readings</h3>
                        <ul>
                            {readings.map((reading, index) => (
                                <li key={index}>
                                    <a href={reading.link} target="_blank" rel="noopener noreferrer">{reading.title}</a>
                                    <p className="description">{reading.description}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {activeTab === 'datasets' && (
                    <div className="resources-section">
                        <h3>Sample Datasets</h3>
                        <ul>
                            {datasets.map((dataset, index) => (
                                <li key={index}>
                                    <a href={dataset.link} target="_blank" rel="noopener noreferrer">{dataset.title}</a>
                                    <p className="description">{dataset.description}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {activeTab === 'tutorials' && !selectedTutorial && (
                    <div className="resources-section">
                        <h3>Tutorials</h3>
                        <ul>
                            {tutorials.map((tutorial, index) => (
                                <li key={index}>
                                    <span className="clickable-tutorial">{tutorial.title}</span>
                                    {/* Display "Coming Soon" for now */}
                                    <p className="description">Coming soon...</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {selectedTutorial && selectedTutorial.videoUrl !== 'coming_soon' && (
                    <div className="tutorial-view">
                        <button className="back-btn" onClick={handleBackToTutorials}>Back to Tutorials</button>
                        <h3>{selectedTutorial.title}</h3>
                        <iframe
                            width="100%"
                            height="480"
                            src={selectedTutorial.videoUrl}
                            title={selectedTutorial.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Resources;
