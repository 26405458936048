import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchBundles = () => {
    const [bundles, setBundles] = useState([]); // State to hold fetched bundles
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBundles = async () => {
            try {
                const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundles`, {
                    params: { role: 'admin' }, // Ensure the role is passed as 'admin'
                });
                setBundles(response.data); // Set bundles in state
            } catch (err) {
                setError(err); // Set error if there's an issue
            } finally {
                setLoading(false); // Set loading to false once data is fetched or an error occurs
            }
        };

        fetchBundles(); // Fetch bundles when component mounts
    }, []); // No dependencies, so it only runs once

    return { bundles, loading, error }; // Return the bundles, loading status, and any errors
};

export default useFetchBundles;
