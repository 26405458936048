import React from 'react';
import { Link } from 'react-router-dom';
import './AdminPage.css';

const AdminPage = () => {
    return (
        <div className="admin-page">
            <h2 className="admin-title">Admin Dashboard</h2>
            <div className="admin-links">
                <Link to="/admin/user-management" className="admin-link">User Management</Link>
                <Link to="/admin/toolkit-management" className="admin-link">ML Toolkit Management</Link>
                <Link to="/admin/case-study-management" className="admin-link">Case Study Management</Link> {/* Updated route */}
                <Link to="/admin/instructor-requests" className="admin-link">Instructor Requests</Link>
            </div>
        </div>
    );
};

export default AdminPage;
