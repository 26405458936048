import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchBundleCreators = () => {
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCreators = async () => {
      try {
        const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundle-creators`);
        setCreators(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCreators();
  }, []);

  return { creators, loading, error };
};

export default useFetchBundleCreators;
