import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import customAxios from '../customAxios';

const useFetchUserAuthorizations = () => {
    const [authorizedProducts, setAuthorizedProducts] = useState({
        resources: {},
        caseStudies: {},
        mlKits: {}
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {
        const fetchAuthorizations = async () => {
            if (!user) {
                setLoading(false);
                return;
            }

            try {
                const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/userAuthorizations/${user.uid}`);

                // Update all authorization fields (resources, caseStudies, mlKits)
                setAuthorizedProducts({
                    caseStudies: response.data.caseStudies || {},
                    mlKits: response.data.mlKits || {}
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAuthorizations();
    }, [user]);

    return { authorizedProducts, loading, error };
};

export default useFetchUserAuthorizations;
