import React from "react";

const faqs = [
    {
        category: "SCOPE",
        questions: [
            {
                question: "What is StatLeap, and who can use it?",
                answer: "StatLeap is a subscription-based platform that provides no-code machine learning tools designed for users at all experience levels, especially those without coding knowledge. It's suitable for data analysts, students, and professionals looking to conduct machine learning analyses without writing code."
            },
            {
                question: "Do I need coding skills to use StatLeap tools?",
                answer: "No, StatLeap is specifically designed for users without coding skills, offering intuitive interfaces for a variety of machine learning analyses."
            },
            {
                question: "What types of machine learning models can I build with StatLeap?",
                answer: "You can build models including regression, binary logit, factor analysis, cluster insights, marketing mix models, and more."
            },
            {
                question: "What types of analysis are included in the basic plan?",
                answer: "The basic plan provides access to essential tools like data exploration, regression, factor analysis, cluster insights, and more."
            },
            {
                question: "Can I use StatLeap to analyze real-world data from uploaded files?",
                answer: "Yes, StatLeap allows users to upload and analyze their own datasets, making it suitable for both academic and professional purposes."
            },
            {
                question: "Are StatLeap's tools suitable for advanced users, or are they mainly for beginners?",
                answer: "StatLeap is designed for both beginners and advanced users. Beginners benefit from the no-code interface, while advanced users can perform in-depth analyses without needing programming skills."
            },
            {
                question: "What types of datasets are available for exploration and learning?",
                answer: "StatLeap offers datasets that span various fields like marketing, customer behavior, and business analytics, allowing users to practice real-world analyses."
            },
        ]
    },
    {
        category: "SUBSCRIPTION",
        questions: [
            {
                question: "How do I register for StatLeap, and what information is required during signup?",
                answer: "You can register for StatLeap using your Google account, which can be associated with any email address. There is no need to provide additional personal details or create a password, making the registration process simple and secure."
            },
            {
                question: "Can I access case studies, datasets, or readings before purchasing a subscription?",
                answer: "Case studies and datasets may be accessible for preview, but full access to StatLeap�s resources typically requires a subscription."
            },
            {
                question: "Is there a free trial available before purchasing a subscription?",
                answer: "Currently, StatLeap does not have a free trial. Users must subscribe to one of the available plans to access tools."
            },
            {
                question: "What subscription plans are available, and what do they include?",
                answer: "StatLeap offers monthly, quarterly, and annual subscription plans. Each plan provides access to various machine learning tools, with extended support and features in higher-tier plans."
            },
            {
                question: "What are the limitations of the basic subscription compared to premium plans?",
                answer: "The basic subscription provides access to a limited number of machine learning tools and case studies. Premium plans offer more comprehensive access to a wider range of tools, additional datasets, and more case studies. The length of access also differs, with premium plans providing extended usage periods and more features for in-depth learning and analysis."
            },
            {
                question: "Can I upload my own datasets for analysis, and what file formats are supported?",
                answer: "Yes, users can upload their own datasets to perform analyses using StatLeap�s tools. Currently, only CSV files are supported for data uploads. Other formats like Excel are not accepted."
            },
        ]
    },
    {
        category: "SUPPORT",
        questions: [
            {
                question: "How can I get support if I encounter issues?",
                answer: "StatLeap offers customer support, and users can access additional help via priority support on higher-tier plans."
            },
            {
                question: "Are there any tutorials or guides to help me get started with each tool?",
                answer: "Yes, StatLeap includes tutorials and guides for each tool to help users get started and understand the functionalities."
            },
            {
                question: "How frequently are new tutorials or case studies added to the platform?",
                answer: "The platform updates tutorials and case studies periodically, though the exact frequency may vary depending on new content releases."
            },
            {
                question: "How can I access customer support for technical issues?",
                answer: "Customer support is available through email or the platform�s support system. Priority support is provided for users with higher-tier subscriptions."
            },
            {
                question: "How does StatLeap ensure the security and privacy of my uploaded data?",
                answer: "StatLeap does not store any datasets uploaded by users. Once the analysis is completed, the datasets are deleted to ensure privacy. Users are responsible for keeping their data secure on their own devices."
            },
            {
                question: "What happens to my data and access if my subscription expires?",
                answer: "If your subscription expires, you will lose access to StatLeap's tools and features. However, since StatLeap does not store datasets, no uploaded data will be retained on the platform after usage."
            },
        ]
    }
];

const FAQ = () => {
    return (
        <div className="relative w-full bg-white max-w-[1240px] mx-auto mt-[90px] px-4 py-16 pt-10 pb-8 sm:mx-auto sm:rounded-lg sm:px-10">
            <div className="mx-auto px-5">
                <div className="flex flex-col items-center">
                    <h2 className="mt-5 text-center text-4xl font-bold mb-2 text-[#25d366] md:text-5xl">FAQ</h2>
                    <p className="mt-3 text-lg text-gray-600 mb-4 md:text-xl">Any Questions? Look Here</p>
                </div>
                <div className="w-max-lg mx-auto mt-8 grid divide-y divide-neutral-200">
                    {faqs.map((category, index) => (
                        <div key={index}>
                            <h3 className="text-2xl font-semibold text-[#25d366] mt-8">{category.category}</h3>
                            {category.questions.map((faq, idx) => (
                                <div className="py-6" key={idx}>
                                    <details className="group">
                                        <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                                            <span className="text-lg md:text-xl text-black">{faq.question}</span>
                                            <span className="text-black transition group-open:rotate-180">
                                                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="group-open:animate-fadeIn mt-3 text-neutral-600">{faq.answer}</p>
                                    </details>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
