import React, { useEffect } from 'react';
import useFetchInstructorRequests from '../hooks/useFetchInstructorRequests';
import useUpdateInstructorRequest from '../hooks/useUpdateInstructorRequest';
import './AdminInstructorRequests.css';

const AdminInstructorRequests = () => {
  const { requests, loading, error, fetchRequests } = useFetchInstructorRequests(); // Include fetchRequests
  const { updateRequest, loading: updateLoading, error: updateError } = useUpdateInstructorRequest();

  useEffect(() => {
    fetchRequests(); // Fetch requests when the component mounts
  }, []);

  const handleApprove = async (requestId) => {
    await updateRequest(requestId, 'approved');
    fetchRequests(); // Refresh the list after approval
  };

  const handleDecline = async (requestId) => {
    await updateRequest(requestId, 'declined');
    fetchRequests(); // Refresh the list after decline
  };

  if (loading || updateLoading) return <div>Loading...</div>;
  if (error || updateError) return <div>Error: {error?.message || updateError?.message}</div>;

  return (
    <div className="admin-instructor-requests">
      <h2>Pending Instructor Requests</h2>
      {requests.length === 0 ? (
        <p>No pending requests</p>
      ) : (
        <table className="requests-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Institution</th>
              <th>Institution Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request) => (
              <tr key={request.id}>
                <td>{request.legalName}</td>
                <td>{request.institution}</td>
                <td>{request.institutionEmail}</td>
                <td>
                  <button onClick={() => handleApprove(request.id)} className="approve-btn">Approve</button>
                  <button onClick={() => handleDecline(request.id)} className="decline-btn">Decline</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminInstructorRequests;
