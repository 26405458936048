import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchMlKits = () => {
  const [mlKits, setMlKits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMlKits = async () => {
      try {
        const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/mlKits`);
        setMlKits(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchMlKits();
  }, []);

  return { mlKits, loading, error };
};

export default useFetchMlKits;
