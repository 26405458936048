import { auth } from './firebase';
import { GoogleAuthProvider, signInWithPopup, signOut as firebaseSignOut } from 'firebase/auth';
import customAxios from './customAxios';

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;

        // Get the ID token from Firebase
        const idToken = await user.getIdToken();

        // Call the server to register or update the user and get the session ID
        const response = await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/registerOrUpdateUser`, {
            userId: user.uid,
            email: user.email,
            displayName: user.displayName,
        }, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        // Store the session ID in localStorage
        localStorage.setItem('sessionId', response.data.sessionId);

        return user;
    } catch (error) {
        console.error('Error during sign-in:', error);
        // Handle errors here
        throw error;
    }
};

const signOut = () => {
    return firebaseSignOut(auth)
        .then(() => {
            // Clear the session ID from localStorage on sign out
            localStorage.removeItem('sessionId');
        })
        .catch((error) => {
            console.error('Error during sign-out:', error);
            // Handle errors here
        });
};

export { signInWithGoogle, signOut };
