import React, { useState, useEffect } from 'react';
import useFetchCaseStudies from '../hooks/useFetchCaseStudies';
import useFetchUserAuthorizations from '../hooks/useFetchUserAuthorizations';
import useAuth from '../hooks/useAuth';
import './CaseStudies.css';

const CaseStudies = () => {
    const { caseStudies, loading: caseStudiesLoading, error: caseStudiesError } = useFetchCaseStudies(); // Fetch all case studies
    const { authorizedProducts, loading: authLoading, error: authError } = useFetchUserAuthorizations(); // Fetch authorized case studies
    const { userRole } = useAuth(); // Fetch user role
    const [selectedCaseStudy, setSelectedCaseStudy] = useState(null); // Track selected case study

    useEffect(() => {
        setSelectedCaseStudy(null); // Reset selected case study on page load
    }, []);

    const isCaseStudyAuthorized = (caseStudyId) => {
        const caseStudyAuth = authorizedProducts?.caseStudies || {};
        const authorizationData = caseStudyAuth[caseStudyId];

        if (!authorizationData) {
            return false; // Not authorized
        }

        const expirationSeconds = authorizationData._seconds?.seconds || authorizationData.seconds || authorizationData._seconds;
        if (!expirationSeconds) {
            return false; // No valid expiration data
        }

        const expirationDate = new Date(expirationSeconds * 1000); // Convert to JavaScript Date
        const now = new Date();
        return expirationDate > now; // Return true if the expiration date is in the future
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const extractFileName = (url, maxLength = 20) => {
        const parts = url.split('/');
        let fileName = decodeURIComponent(parts[parts.length - 1].split('?')[0]); // Remove query params

        // Clean up folder prefixes (e.g., "pdfDocuments/")
        const cleanedUrl = url.replace(/.*\/o\//, ''); // Removes everything up to "/o/"
        fileName = decodeURIComponent(cleanedUrl.split('%2F').pop().split('?')[0]); // Decode and remove query params

        // Truncate long file names if necessary
        if (fileName.length > maxLength) {
            fileName = fileName.substring(0, maxLength - 3) + '...'; // Add ellipsis for truncated names
        }

        return fileName;
    };



    if (caseStudiesLoading || authLoading) return <div>Loading...</div>;
    if (caseStudiesError || authError) return <div>Error fetching case studies: {caseStudiesError?.message || authError?.message}</div>;

    const handleCaseStudyClick = (caseStudy) => {
        if (isCaseStudyAuthorized(caseStudy.id) || userRole === 'admin' || (userRole === 'instructor' && !caseStudy.isPrivate)) {
            setSelectedCaseStudy(caseStudy);
        }
    };

    return (
        <div className="case-studies-page">
            {selectedCaseStudy ? (
                <div className="case-study-details">
                    <h2>{selectedCaseStudy.name}</h2>
                    <p>{selectedCaseStudy.description}</p>
                    <div className="case-study-documents">
                        <div className="pdf-documents">
                            <h5>PDF Documents</h5>
                            <ul>
                                {selectedCaseStudy.pdfDocuments.length > 0 ? (
                                    selectedCaseStudy.pdfDocuments.map((pdf, index) => (
                                        <li key={index}>
                                            <span className="material-icons pdf-icon">picture_as_pdf</span>
                                            <a href={pdf} target="_blank" rel="noopener noreferrer">
                                                {extractFileName(pdf)}
                                            </a>
                                        </li>
                                    ))
                                ) : (
                                    <li>No PDF documents available</li>
                                )}
                            </ul>
                        </div>

                        {selectedCaseStudy.supplementalDocuments.length > 0 && (
                            <div className="supplemental-documents">
                                <h5>Supplemental Documents</h5>
                                <ul>
                                    {selectedCaseStudy.supplementalDocuments.map((supplemental, index) => (
                                        <li key={index}>
                                            <span className="material-icons supplemental-icon">insert_drive_file</span>
                                            <a href={supplemental} target="_blank" rel="noopener noreferrer">
                                                {extractFileName(supplemental)}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <button className="back-button" onClick={() => setSelectedCaseStudy(null)}>
                        Back to Case Studies
                    </button>
                </div>
            ) : (
                <div className="case-studies-list">
                    {caseStudies.map((caseStudy) => {
                        const authorized = isCaseStudyAuthorized(caseStudy.id) || userRole === 'admin' || (userRole === 'instructor' && !caseStudy.isPrivate);

                        return (
                            <div
                                key={caseStudy.id}
                                className={`case-study-item ${authorized ? '' : 'case-study-disabled'}`}
                                onClick={() => handleCaseStudyClick(caseStudy)}
                                style={{ cursor: authorized ? 'pointer' : 'not-allowed' }}
                            >
                                <img src={caseStudy.imageUrl || '/default-image.jpg'} alt={caseStudy.name} />
                                <h4>{truncateText(caseStudy.name, 50)}</h4>
                                <p>{truncateText(caseStudy.description, 100)}</p>
                                {caseStudy.tags && caseStudy.tags.length > 0 && (
                                    <div className="tags-list">
                                        {caseStudy.tags.map((tag, index) => (
                                            <span key={index} className="tag">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                )}
                                <button
                                    disabled={!authorized}
                                    className={`case-study-btn ${authorized ? '' : 'case-study-btn-disabled'}`}
                                >
                                    {authorized ? 'Open' : 'Not Authorized'}
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default CaseStudies;
