import React from "react";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import WhyStatLeap from "./WhyStatLeap";
import Benefits from "./Benefits";
import ThreeStepDiagram from "./ThreeStepDiagram";  // Import the new component
import Footer from "./Footer";
import './HeroPage.css';

const HeroPage = () => {
    return (
        <div className="hero-page-container">
            {/* Hero Section */}
            <Hero />

            {/* Why StatLeap Section */}
            <div className="why-stat-leap-section">
                <WhyStatLeap />
            </div>

            {/* Three-Step Diagram Section */}
            <div className="three-step-section">
                <ThreeStepDiagram />
            </div>

            {/* ML Kits Promo Image Section */}
            <div className="mlkits-screenshot-section">
                <h2 className="mlkits-title">Explore Available ML Kits</h2>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/statleaptest.appspot.com/o/mlkits%2Fmlkits-promo.png?alt=media&token=3b40b780-df04-492f-a14c-5968cefd08f8"
                    alt="ML Kits Promo"
                    className="mlkits-screenshot"
                />
            </div>

            {/* How It Works Section */}
            <div className="how-it-works-section">
                <HowItWorks />
            </div>

            {/* Benefits Section */}
            <div className="benefits-section">
                <Benefits />
            </div>

            {/* Footer Section */}
            <Footer />
        </div>
    );
};

export default HeroPage;
