import { useState } from 'react';
import customAxios from '../customAxios';

const useRequestInstructorStatus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const requestInstructorStatus = async (requestData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/request-instructor-status`, requestData);
      setSuccess(response.data.message);
    } catch (err) {
      setError(err.response?.data?.message || 'Error submitting instructor request');
    } finally {
      setLoading(false);
    }
  };

  return { requestInstructorStatus, loading, error, success };
};

export default useRequestInstructorStatus;
