import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Footer.css'; // Import the CSS file

const Footer = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <footer>
            <div className={`footer-container ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="footer-header" onClick={toggleCollapse}>
                    <h1 className="footer-logo">
                        Stat<span className="footer-logo-highlight">Leap</span>
                    </h1>
                    <button className="toggle-button">
                        {isCollapsed ? 'Expand' : 'Collapse'}
                    </button>
                </div>
                <div className={`footer-content ${isCollapsed ? 'collapsed' : 'expanded'}`}>
                    <div className="footer-columns">
                        <div className="footer-column">
                            <h6 className="footer-heading">Quick Links</h6>
                            <ul>
                                <li><Link to="/ml-tools">ML Tools</Link></li>
                                <li><Link to="/resources">Case Studies</Link></li>
                                <li><Link to="/purchase-bundle">Purchase Bundle</Link></li>
                                <li><Link to="/faq">FAQ</Link></li> {/* New Link */}
                                <li><Link to="/support">Support</Link></li> {/* New Link */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
