import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './NavbarLinks.css';

const NavbarLinks = ({ user, userRole, handleLogout, closeSubmenus, onMLKitsClick }) => {
    const location = useLocation();
    const [submenuOpen, setSubmenuOpen] = useState(false); // State for the Support submenu

    const handleNavLinkClick = (path, callback) => {
        if (location.pathname === path && callback) {
            callback(); // Trigger the callback to force re-render
        }
        closeSubmenus(); // Close the mobile menu if it's open
    };

    const toggleSubmenu = () => {
        setSubmenuOpen(!submenuOpen); // Toggle the submenu open/close
    };

    return (
        <ul className="navbar-links">
            <li className="navbar-link-item">
                <NavLink to="/ml-tools" onClick={() => handleNavLinkClick('/ml-tools', onMLKitsClick)}>ML Tools</NavLink>
            </li>

            <li className="navbar-link-item">
                <NavLink to="/case-studies" onClick={closeSubmenus}>Case Studies</NavLink>
            </li>

            <li className="navbar-link-item">
                <NavLink to="/resources" onClick={closeSubmenus}>Resources</NavLink>
            </li>

            <li className="navbar-link-item">
                <NavLink to="/purchase-bundle" onClick={closeSubmenus}>Bundles</NavLink>
            </li>

            {/* Support Dropdown Submenu */}
            <li className="navbar-link-item">
                <span onClick={toggleSubmenu} className="submenu-title">Support</span>
                {submenuOpen && (
                    <ul className="navbar-submenu">
                        <li className="navbar-submenu-item">
                            <NavLink to="/support" onClick={closeSubmenus}>Contact Us</NavLink>
                        </li>
                        <li className="navbar-submenu-item">
                            <NavLink to="/faq" onClick={closeSubmenus}>FAQ</NavLink>
                        </li>
                    </ul>
                )}
            </li>

            {user && (userRole === 'admin' || userRole === 'instructor') && (
                <li className="navbar-link-item">
                    <NavLink to="/instructor-dashboard" onClick={closeSubmenus}>Instructor</NavLink>
                </li>
            )}

            {user && userRole === 'admin' && (
                <li className="navbar-link-item">
                    <NavLink to="/admin" onClick={closeSubmenus}>Admin</NavLink>
                </li>
            )}

            {user ? (
                <>
                    <li className="navbar-link-item">
                        <NavLink to="/profile" onClick={closeSubmenus}>Profile</NavLink>
                    </li>
                    <li className="navbar-link-item">
                        <button onClick={handleLogout} className="whitespace-nowrap">Logout</button>
                    </li>
                </>
            ) : (
                <li className="navbar-link-item">
                    <NavLink to="/login" onClick={closeSubmenus}>Login / Register</NavLink>
                </li>
            )}
        </ul>
    );
};

export default NavbarLinks;
