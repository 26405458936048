import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useCheckInstructorRequestStatus = (userId) => {
  const [status, setStatus] = useState(null);
  const [canResubmit, setCanResubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkRequestStatus = async () => {
      try {
        const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/instructor-request-status/${userId}`);
        setStatus(response.data.status);
        if (response.data.status === 'declined') {
          setCanResubmit(response.data.canResubmit);
        }
      } catch (err) {
        setError(err.response?.data?.message || 'Error checking request status');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      checkRequestStatus();
    }
  }, [userId]);

  return { status, canResubmit, loading, error };
};

export default useCheckInstructorRequestStatus;
