import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

// Debounce delay in milliseconds
const DEBOUNCE_DELAY = 300;

const useCalculateBundlePrice = (selectedResources, selectedMlKits, resources, mlKits, period) => {
    const [bundlePrice, setBundlePrice] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // A flag to cancel the previous request if needed
        let cancelRequest = false;

        // Function to fetch prices
        const fetchPricesForSelectedItems = async () => {
            if (!selectedResources.length && !selectedMlKits.length) {
                setBundlePrice(0); // Set price to 0 if no resources or kits are selected
                return;
            }

            setIsLoading(true);
            let total = 0;

            const fetchPrice = async (priceId) => {
                try {
                    const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/prices/${priceId}`);
                    return response.data.unit_amount / 100; // Convert cents to dollars
                } catch (error) {
                    console.error(`Error fetching price with ID ${priceId}:`, error);
                    return 0;
                }
            };

            // Fetch prices for selected resources
            for (const resourceId of selectedResources) {
                const resource = resources.find(r => r.id === resourceId);
                if (resource && resource.priceId && !cancelRequest) {
                    const price = await fetchPrice(resource.priceId);
                    total += price;
                }
            }

            // Fetch prices for selected ML kits
            for (const mlKitId of selectedMlKits) {
                const mlKit = mlKits.find(k => k.id === mlKitId);
                if (mlKit && mlKit.priceId && !cancelRequest) {
                    const price = await fetchPrice(mlKit.priceId);
                    total += price;
                }
            }

            // If request was not cancelled, update the bundle price
            if (!cancelRequest) {
                setBundlePrice((total * period).toFixed(2)); // Calculate total price based on the period
                setIsLoading(false);
            }
        };

        // Debounce the API call
        const debounceTimer = setTimeout(() => {
            fetchPricesForSelectedItems();
        }, DEBOUNCE_DELAY);

        // Cleanup function: cancel request and clear debounce timer if dependencies change
        return () => {
            cancelRequest = true;
            clearTimeout(debounceTimer);
        };
    }, [selectedResources, selectedMlKits, period, resources, mlKits]);

    return { bundlePrice, isLoading };
};

export default useCalculateBundlePrice;
