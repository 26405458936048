import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchBundle = (bundleId) => {
  const [bundle, setBundle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBundle = async () => {
      try {
        const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundles/${bundleId}`);
        setBundle(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (bundleId) {
      fetchBundle();
    }
  }, [bundleId]);

  return { bundle, loading, error };
};

export default useFetchBundle;
