import React, { useState, useEffect, useRef } from 'react';
import useFetchMlKits from '../hooks/useFetchMlKits'; // Use the hook to fetch all ML kits
import useFetchAuthorizedMlKits from '../hooks/useFetchAuthorizedMlKits'; // Use the hook to fetch authorized ML kits
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import './MLKits.css';

const MLKits = () => {
    const { mlKits, loading: mlKitsLoading, error: mlKitsError } = useFetchMlKits(); // Fetch all ML kits
    const { authorizedMlKits, loading: authorizedLoading, error: authorizedError } = useFetchAuthorizedMlKits(); // Fetch authorized ML kits
    const [selectedMlKit, setSelectedMlKit] = useState(null);
    const [userId, setUserId] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);
    const iframeRef = useRef(null);
    const location = useLocation();

    // Handle user authentication
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
            } else {
                setUserId(null);
            }
            setAuthLoading(false);
        });

        return () => unsubscribe();
    }, []);

    // Reset selectedMlKit on location change (NavLink click)
    useEffect(() => {
        setSelectedMlKit(null);
    }, [location]);

    // Handle iframe communication and parent page scrolling
    useEffect(() => {
        if (selectedMlKit && iframeRef.current && userId) {
            const sessionId = localStorage.getItem('sessionId');
            iframeRef.current.onload = () => {
                sendSessionInfoToIframe(iframeRef.current, sessionId, userId);
            };
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [selectedMlKit, userId]);

    const sendSessionInfoToIframe = (iframe, sessionId, userId) => {
        if (iframe && sessionId && userId) {
            try {
                iframe.contentWindow.postMessage({ sessionId, userId }, '*');
            } catch (error) {
                console.error('Error sending session info to iframe:', error);
            }
        }
    };

    if (authLoading || mlKitsLoading || authorizedLoading) return <div>Loading...</div>;
    if (mlKitsError || authorizedError) return <div>Error fetching ML kits: {mlKitsError?.message || authorizedError?.message}</div>;

    // Check if ML Kit is authorized
    const isMlKitAuthorized = (mlKitId) => {
        return authorizedMlKits.some(authMlKit => authMlKit.id === mlKitId);
    };

    const handleMlKitClick = (mlKit) => {
        if (isMlKitAuthorized(mlKit.id)) {
            setSelectedMlKit(mlKit.id); 
        }
    };

    return (
        <div className="mlkits-page">
            {selectedMlKit ? (
                <div className="mlkit-viewer">
                    <div className="mlkit-iframe">
                        <iframe
                            ref={iframeRef}
                            src={authorizedMlKits.find(mlKit => mlKit.id === selectedMlKit)?.shinyAppsUrl}
                            title="ML Kit"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 'none', height: '95vh' }}
                        ></iframe>
                    </div>
                </div>
            ) : (
                <div className="mlkits-list">
                    {mlKits
                        .filter(mlKit => !mlKit.isPrivate) // Show only non-private ML kits
                        .map(mlKit => (
                            <div
                                key={mlKit.id}
                                className={`mlkit-item ${isMlKitAuthorized(mlKit.id) ? '' : 'mlkit-disabled'}`} 
                                onClick={() => isMlKitAuthorized(mlKit.id) && handleMlKitClick(mlKit)}
                                style={{ cursor: isMlKitAuthorized(mlKit.id) ? 'pointer' : 'not-allowed' }} 
                            >
                                <img src={mlKit.imageUrl} alt={mlKit.toolName} />
                                <h4>{mlKit.toolName}</h4>
                                <p>{mlKit.description}</p>
                                <button
                                    disabled={!isMlKitAuthorized(mlKit.id)} 
                                    className={`mlkit-btn ${isMlKitAuthorized(mlKit.id) ? '' : 'mlkit-btn-disabled'}`} 
                                >
                                    {isMlKitAuthorized(mlKit.id) ? 'Open' : 'Not Authorized'}
                                </button>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default MLKits;
