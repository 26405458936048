import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useFetchUser from '../hooks/useFetchUser';
import useFetchBundle from '../hooks/useFetchBundle';
import useRequestInstructorStatus from '../hooks/useRequestInstructorStatus';
import useCheckPendingInstructorRequest from '../hooks/useCheckPendingInstructorRequest';
import './Profile.css';

const Profile = () => {
  const { user: authUser, loading: authLoading } = useAuth();
  const { user, loading: userLoading, error: userError } = useFetchUser(authUser?.uid);
  const { requestInstructorStatus, loading: requestLoading, error: requestError, success: requestSuccess } = useRequestInstructorStatus();
  const { status: requestStatus, canResubmit, loading: requestCheckLoading, error: requestCheckError } = useCheckPendingInstructorRequest(authUser?.uid);
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    legalName: '',
    institution: '',
    institutionEmail: '',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitRequest = () => {
    requestInstructorStatus({
      ...formData,
      userId: authUser.uid,
      email: authUser.email,
    });
  };

  if (authLoading || userLoading || requestCheckLoading) return <div>Loading...</div>;
  if (userError || requestCheckError) return <div>Error fetching data: {userError?.message || requestCheckError?.message}</div>;

  return (
    <div className="profile-page">
      <h2>Profile</h2>
      <div className="profile-details">
        <p><strong>Name:</strong> {user?.displayName || 'N/A'}</p>
        <p><strong>Email:</strong> {user?.email || 'N/A'}</p>
        <p><strong>Role:</strong> {user?.role || 'N/A'}</p>
        <p><strong>Access Expiration Date:</strong> {user?.accessExpirationDate ? new Date(user.accessExpirationDate._seconds * 1000).toLocaleDateString() : 'N/A'}</p>
      </div>

      <h3>Purchased Bundles</h3>
      {user?.purchasedBundles && user.purchasedBundles.length > 0 ? (
        <div className="purchased-bundles">
          {user.purchasedBundles.map((bundleInfo, index) => (
            <BundleDetails key={index} bundleId={bundleInfo.bundleId} expirationDate={bundleInfo.expirationDate} />
          ))}
        </div>
      ) : (
        <p>No bundles purchased yet.</p>
      )}

      
    </div>
  );
};

const BundleDetails = ({ bundleId, expirationDate }) => {
  const { bundle, loading: bundleLoading, error: bundleError } = useFetchBundle(bundleId);
  const { user: bundleCreator, loading: creatorLoading, error: creatorError } = useFetchUser(bundle?.userId);

  useEffect(() => {
    if (bundle) {
      //console.log(`Fetched bundle: ${bundle.name}`);
    }
    if (bundleCreator) {
      //console.log(`Fetched bundle creator: ${bundleCreator.displayName}`);
    }
  }, [bundle, bundleCreator]);

  if (bundleLoading || creatorLoading) return <div>Loading bundle details...</div>;
  if (bundleError) return <div>Error fetching bundle details: {bundleError.message}</div>;
  if (creatorError) return <div>Error fetching bundle creator details: {creatorError.message}</div>;

  return (
    <div className="bundle-details">
      <p><strong>Bundle Name:</strong> {bundle?.name || 'N/A'}</p>
      <p><strong>Description:</strong> {bundle?.description || 'N/A'}</p>
      <p><strong>Expiration Date:</strong> {new Date(expirationDate._seconds * 1000).toLocaleDateString()}</p>
    </div>
  );
};

export default Profile;
