import { useState } from 'react';
import customAxios from '../customAxios';

const useUpdateUserAuthorizations = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateUserAuthorizations = async (userId, authorizations) => {
    setLoading(true);
    setError(null);
    setSuccess(false);
	//console.log(authorizations);
    try {
      await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}/authorizations`, authorizations);
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { updateUserAuthorizations, loading, error, success };
};

export default useUpdateUserAuthorizations;
