import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchCaseStudies = () => {
    const [caseStudies, setCaseStudies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCaseStudies = async () => {
            try {
                const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/case-studies`);
                setCaseStudies(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCaseStudies();
    }, []);

    return { caseStudies, loading, error };
};

export default useFetchCaseStudies;
