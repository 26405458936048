import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const ProtectedRoute = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const location = useLocation();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsLoading(false); // Stop loading once the auth state is resolved
        });

        return () => unsubscribe();
    }, [auth]);

    if (isLoading) {
        return <div>Loading...</div>; // You can customize this with a loading spinner if needed
    }

    if (!user) {
        return <Navigate to="/login" state={{ from: location, message: 'Please log in to access this page' }} />;
    }

    return children;
};

export default ProtectedRoute;
