import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth,signOut } from 'firebase/auth';
import { getStorage } from 'firebase/storage';  // Import Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyBlgUOWtcMqX-t4RBWHKAEsMxxKn2b2dg4",
  authDomain: "statleaptest.firebaseapp.com",
  projectId: "statleaptest",
  storageBucket: "statleaptest.appspot.com",
  messagingSenderId: "60723328617",
  appId: "1:60723328617:web:8cf19cff101ab3293654f8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);  // Initialize Firebase Storage

export { app, db, auth, storage, signOut };
