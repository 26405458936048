import { useState } from "react";
import customAxios from "../customAxios";

const useRedeemFreeBundle = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const redeemFreeBundle = async (bundleId, userId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/redeem-free-bundle`, {
        bundleId,
        userId,
      });
      return response.data;
    } catch (err) {
      setError("Error redeeming free bundle.");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { redeemFreeBundle, loading, error };
};

export default useRedeemFreeBundle;
