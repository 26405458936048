import React, { useState, useEffect } from 'react';
import customAxios from '../customAxios';
import useUpdateUserRole from '../hooks/useUpdateUserRole';
import useUpdateUserAuthorizations from '../hooks/useUpdateUserAuthorizations';
import './UserDetail.css';

const UserDetail = ({ user }) => {
    const [role, setRole] = useState(user.role || 'user');
    const [authorizedMlKits, setAuthorizedMlKits] = useState({});
    const [authorizedCaseStudies, setAuthorizedCaseStudies] = useState({});
    const [availableMlKits, setAvailableMlKits] = useState([]);
    const [availableCaseStudies, setAvailableCaseStudies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [pendingRole, setPendingRole] = useState('');

    const { updateUserRole, loading: roleLoading, error: roleError } = useUpdateUserRole();
    const { updateUserAuthorizations, loading: authLoading, error: authError } = useUpdateUserAuthorizations();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                // Fetch user details from the backend
                const userResponse = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${user.id}`);
                const userDetails = userResponse.data;
                setRole(userDetails.role || 'user');

                // Fetch user authorizations from the backend
                const userAuthResponse = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/userAuthorizations/${user.id}`);
                const { mlKits = {}, caseStudies = {} } = userAuthResponse.data;
                setAuthorizedMlKits(mlKits);
                setAuthorizedCaseStudies(caseStudies);

                // Fetch available ML Kits and Case Studies from the backend
                const [mlKitsResponse, caseStudiesResponse] = await Promise.all([
                    customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/mlKits`),
                    customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/case-studies`)
                ]);
                setAvailableMlKits(mlKitsResponse.data);
                setAvailableCaseStudies(caseStudiesResponse.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [user.id]);

    const handleRoleChange = (newRole) => {
        if (newRole === 'admin' || newRole === 'instructor') {
            setPendingRole(newRole);
            setShowConfirmPopup(true);
        } else {
            updateRole(newRole);
        }
    };

    const updateRole = (newRole) => {
        updateUserRole(user.id, newRole);
        setShowConfirmPopup(false);
        setRole(newRole);
    };

    const convertUnixToDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Convert Unix time to milliseconds for JS Date
        return date.toLocaleDateString(); // Format date as needed
    };

    const handleAuthorizationChange = (type, id, authorized) => {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        const unixTimestamp = Math.floor(expirationDate.getTime() / 1000); // Convert to Unix time (seconds)

        let updatedAuth;

        if (type === 'mlKits') {
            updatedAuth = { ...authorizedMlKits };
            if (authorized) {
                updatedAuth[id] = { _seconds: unixTimestamp, _nanoseconds: 0 }; // Send Unix time only
            } else {
                delete updatedAuth[id];
            }
            setAuthorizedMlKits(updatedAuth);
        } else if (type === 'caseStudies') {
            updatedAuth = { ...authorizedCaseStudies };
            if (authorized) {
                updatedAuth[id] = { _seconds: unixTimestamp, _nanoseconds: 0 }; // Send Unix time only
            } else {
                delete updatedAuth[id];
            }
            setAuthorizedCaseStudies(updatedAuth);
        }

        const requestBody = {
            mlKits: type === 'mlKits' ? updatedAuth : authorizedMlKits,
            caseStudies: type === 'caseStudies' ? updatedAuth : authorizedCaseStudies,
        };

        updateUserAuthorizations(user.id, requestBody);
    };

    const convertFirestoreTimestampToDate = (timestamp) => {
        const seconds = timestamp.seconds || timestamp._seconds;
        return convertUnixToDate(seconds); // Use seconds field for conversion
    };

    if (loading || roleLoading || authLoading) return <div>Loading...</div>;
    if (error || roleError || authError) return <div>Error fetching data: {error?.message || roleError?.message || authError?.message}</div>;

    return (
        <div className="user-detail">
            <h3>User Detail</h3>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Name:</strong> {user.displayName}</p>
            <label><strong>Role:</strong>
                <select value={role} onChange={(e) => handleRoleChange(e.target.value)}>
                    <option value="user">User</option>
                    <option value="instructor">Instructor</option>
                    <option value="admin">Admin</option>
                </select>
            </label>

            <h4>Authorize/Deauthorize ML Kits</h4>
            {availableMlKits.map(mlKit => (
                <div key={mlKit.id}>
                    <input
                        type="checkbox"
                        checked={!!authorizedMlKits[mlKit.id]}
                        onChange={(e) => handleAuthorizationChange('mlKits', mlKit.id, e.target.checked)}
                    />
                    {mlKit.toolName}
                    {authorizedMlKits[mlKit.id] && (
                        <span> - Authorized until: {convertFirestoreTimestampToDate(authorizedMlKits[mlKit.id])}</span>
                    )}
                </div>
            ))}

            <h4>Authorize/Deauthorize Case Studies</h4>
            {availableCaseStudies.map(caseStudy => (
                <div key={caseStudy.id}>
                    <input
                        type="checkbox"
                        checked={!!authorizedCaseStudies[caseStudy.id]}
                        onChange={(e) => handleAuthorizationChange('caseStudies', caseStudy.id, e.target.checked)}
                    />
                    {caseStudy.name}
                    {authorizedCaseStudies[caseStudy.id] && (
                        <span> - Authorized until: {convertFirestoreTimestampToDate(authorizedCaseStudies[caseStudy.id])}</span>
                    )}
                </div>
            ))}

            {showConfirmPopup && (
                <div className="confirm-popup">
                    <div className="confirm-popup-content">
                        <p>Are you sure you want to give {user.displayName} ({user.email}) {pendingRole} rights?</p>
                        <button onClick={() => updateRole(pendingRole)}>Yes</button>
                        <button onClick={() => setShowConfirmPopup(false)}>No</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserDetail;
