import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/users`);
        const fetchedUsers = response.data;
        
        // Sort users by role, with 'admin' first
        fetchedUsers.sort((a, b) => {
          if (a.role === 'admin' && b.role !== 'admin') return -1;
          if (a.role !== 'admin' && b.role === 'admin') return 1;
          return 0;
        });

        setUsers(fetchedUsers);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { users, loading, error };
};

export default useFetchUsers;
