import { useState } from 'react';
import customAxios from '../customAxios';

const useUpdateBundle = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateBundle = async (bundleId, bundleData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/bundles/${bundleId}`, bundleData);
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { updateBundle, loading, error, success };
};

export default useUpdateBundle;
