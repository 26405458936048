import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchUserBundles = (userId, userRole) => {
  const [bundles, setBundles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBundles = async () => {
    setLoading(true); // Ensure loading state is reset before fetching
    try {
      const queryParams = userRole === 'admin' && userId ? `role=admin&userId=${userId}` : `userId=${userId}`;
      const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundles?${queryParams}`);
      setBundles(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchBundles();
    }
  }, [userId, userRole]);

  return { bundles, loading, error, reloadBundles: fetchBundles };
};

export default useFetchUserBundles;
