import { useState } from 'react';
import customAxios from '../customAxios';

const useCreateBundle = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const createBundle = async (bundleData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      // Send the bundle data to the server where the total price and Stripe details will be calculated and set.
      await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/bundles`, bundleData);
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { createBundle, loading, error, success };
};

export default useCreateBundle;
