import { useState } from 'react';
import customAxios from '../customAxios';

const useUpdateInstructorRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateRequest = async (requestId, status) => {
    setLoading(true);
    setError(null);

    try {
      await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/instructor-requests/${requestId}`, { status });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { updateRequest, loading, error };
};

export default useUpdateInstructorRequest;
