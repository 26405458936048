// utils/exportToCsv.js

export const exportToCsv = (filename, rows) => {
    if (!rows || !rows.length) {
        return;
    }

    // Create the CSV header from the keys of the first object
    const headers = Object.keys(rows[0]);

    // Map each row to a CSV row, joining values with commas
    const csvContent = [
        headers.join(','), // Header row
        ...rows.map(row => headers.map(field => formatCsvField(row[field])).join(',')) // Data rows
    ].join('\n');

    // Create a blob from the CSV content and download it
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// Helper function to handle commas and quotes in CSV data
const formatCsvField = (field) => {
    if (field === null || field === undefined) {
        return '';
    }

    // Convert field to string and escape quotes by doubling them
    const fieldStr = String(field).replace(/"/g, '""');

    // If the field contains a comma, newline, or quote, wrap it in double quotes
    return /[",\n]/.test(fieldStr) ? `"${fieldStr}"` : fieldStr;
};
