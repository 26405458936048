import React, { useState, useEffect } from 'react';
import useCalculateBundlePrice from '../hooks/useCalculateBundlePrice';
import useCreateBundle from '../hooks/useCreateBundle';
import useUpdateBundle from '../hooks/useUpdateBundle';
import './BundleForm.css';

const BundleForm = ({ caseStudies, mlKits, selectedBundle, userId, userRole, onReload }) => {
    const [bundleName, setBundleName] = useState('');
    const [bundleDescription, setBundleDescription] = useState('');
    const [period, setPeriod] = useState(1);
    const [durationInDays, setDurationInDays] = useState(null);
    const [useDays, setUseDays] = useState(false); // Toggle between months and days
    const [selectedCaseStudies, setSelectedCaseStudies] = useState([]);
    const [selectedMlKits, setSelectedMlKits] = useState([]);
    const [overridePrice, setOverridePrice] = useState(0); // Admin override price
    const [useOverridePrice, setUseOverridePrice] = useState(false); // Admin price override toggle
    const [isDataLoaded, setIsDataLoaded] = useState(false); // Track if data is loaded

    const isCreating = !selectedBundle;
    const isAdmin = userRole === 'admin';

    // Determine when data is fully loaded
    useEffect(() => {
        if (caseStudies.length > 0 && mlKits.length > 0) {
            setIsDataLoaded(true);
        }
    }, [caseStudies, mlKits]);

    // Call useCalculateBundlePrice at the top level of the component
    const { bundlePrice, isLoading: isPriceLoading } = useCalculateBundlePrice(selectedCaseStudies, selectedMlKits, caseStudies, mlKits, period);

    const { createBundle, loading: createLoading, success: createSuccess, error: createError } = useCreateBundle();
    const { updateBundle, loading: updateLoading, success: updateSuccess, error: updateError } = useUpdateBundle();

    // Populate form fields with existing bundle data when editing
    useEffect(() => {
        if (selectedBundle && isDataLoaded) {
            setBundleName(selectedBundle.name || '');
            setBundleDescription(selectedBundle.description || '');
            setPeriod(selectedBundle.period || 1);
            setDurationInDays(selectedBundle.durationInDays || null);
            setUseDays(!!selectedBundle.durationInDays); // If the bundle uses days
            setSelectedCaseStudies(selectedBundle.caseStudies || []);
            setSelectedMlKits(selectedBundle.mlKits || []);
            setUseOverridePrice(selectedBundle.bundlePrice !== parseFloat(bundlePrice));
            setOverridePrice(selectedBundle.bundlePrice || 0);
        } else if (!selectedBundle) {
            resetForm();
        }
    }, [selectedBundle, isDataLoaded, bundlePrice]);

    useEffect(() => {
        if (createSuccess || updateSuccess) {
            onReload();
            resetForm();
        }
    }, [createSuccess, updateSuccess, onReload]);

    const resetForm = () => {
        setBundleName('');
        setBundleDescription('');
        setPeriod(1);
        setDurationInDays(null);
        setUseDays(false);
        setSelectedCaseStudies([]);
        setSelectedMlKits([]);
        setUseOverridePrice(false);
        setOverridePrice(0);
    };

    const handleSave = () => {
        const finalPrice = useOverridePrice ? overridePrice : bundlePrice;

        const bundleData = {
            name: bundleName,
            description: bundleDescription,
            caseStudies: selectedCaseStudies,
            mlKits: selectedMlKits,
            period: !useDays ? period : null,
            durationInDays: useDays ? durationInDays : null,
            price: finalPrice,
            userId,
        };

        if (isCreating) {
            createBundle(bundleData);
        } else {
            updateBundle(selectedBundle.id, bundleData);
        }
    };

    const handleCancelEdit = () => {
        resetForm();
        onReload();
    };

    if (!isDataLoaded || isPriceLoading) {
        return <div>Loading...</div>; // Show loading spinner or message while data is loading
    }

    return (
        <div className="bundle-form">
            <h2>{isCreating ? 'Create a New Bundle' : 'Edit Bundle'}</h2>
            <input
                type="text"
                placeholder="Bundle Name"
                value={bundleName}
                onChange={(e) => setBundleName(e.target.value)}
            />
            <textarea
                placeholder="Bundle Description"
                value={bundleDescription}
                onChange={(e) => setBundleDescription(e.target.value)}
            />

            {(isCreating || isAdmin) && (
                <>
                    <label>
                        Toggle Duration Unit:
                        <input
                            type="checkbox"
                            checked={useDays}
                            onChange={(e) => setUseDays(e.target.checked)}
                        />
                        {useDays ? "Use Days" : "Use Months"}
                    </label>

                    {!useDays && (
                        <label>
                            Access Period (Months):
                            <input
                                type="number"
                                min="1"
                                value={period}
                                onChange={(e) => setPeriod(parseInt(e.target.value, 10) || 1)}
                            />
                        </label>
                    )}

                    {useDays && (
                        <label>
                            Access Duration (Days):
                            <input
                                type="number"
                                min="1"
                                value={durationInDays || ''}
                                onChange={(e) => setDurationInDays(parseInt(e.target.value, 10) || 1)}
                            />
                        </label>
                    )}

                    {/* Case Studies Section */}
                    <h3>Select Case Studies</h3>
                    <div className="case-studies-list">
                        {caseStudies.filter(study => !study.isPrivate).map(study => (
                            <div key={study.id}>
                                {study.name}
                                <input
                                    type="checkbox"
                                    checked={selectedCaseStudies.includes(study.id)}
                                    onChange={(e) => {
                                        const updatedSelected = e.target.checked
                                            ? [...selectedCaseStudies, study.id]
                                            : selectedCaseStudies.filter(id => id !== study.id);

                                        setSelectedCaseStudies(updatedSelected);
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    {/* ML Toolkits Section */}
                    <h3>Select ML Toolkits</h3>
                    <div className="mlkits-list">
                        {mlKits.filter(kit => !kit.isPrivate).map(kit => (
                            <div key={kit.id}>
                                {kit.toolName}
                                <input
                                    type="checkbox"
                                    checked={selectedMlKits.includes(kit.id)}
                                    onChange={(e) => {
                                        const updatedSelected = e.target.checked
                                            ? [...selectedMlKits, kit.id]
                                            : selectedMlKits.filter(id => id !== kit.id);

                                        setSelectedMlKits(updatedSelected);
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    {isAdmin && (
                        <>
                            <label>
                                Use Override Price:
                                <input
                                    type="checkbox"
                                    checked={useOverridePrice}
                                    onChange={(e) => setUseOverridePrice(e.target.checked)}
                                />
                            </label>

                            {useOverridePrice && (
                                <label>
                                    Set Override Price:
                                    <input
                                        type="number"
                                        value={overridePrice}
                                        onChange={(e) => setOverridePrice(parseFloat(e.target.value))}
                                        step="0.01"
                                        min="0"
                                    />
                                </label>
                            )}
                        </>
                    )}

                    <h3>Total Bundle Price: ${useOverridePrice ? overridePrice : bundlePrice}</h3>
                </>
            )}

            <button onClick={handleSave} disabled={createLoading || updateLoading || !bundleName || !bundleDescription}>
                {isCreating ? (createLoading ? 'Creating Bundle...' : 'Create Bundle') : (updateLoading ? 'Updating Bundle...' : 'Update Bundle')}
            </button>

            {!isCreating && (
                <button onClick={handleCancelEdit} disabled={createLoading || updateLoading}>
                    Cancel Edit
                </button>
            )}

            {createError && <div className="error">Error creating bundle: {createError.message}</div>}
            {updateError && <div className="error">Error updating bundle: {updateError.message}</div>}
        </div>
    );
};

export default BundleForm;
