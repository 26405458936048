import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './MobileNavbar.css';

const MobileNavbar = ({ nav, handleNav, user, userRole, handleLogout, closeSubmenus }) => {
    const [submenuOpen, setSubmenuOpen] = useState(false); // State for the Support submenu in mobile

    const toggleSubmenu = () => {
        setSubmenuOpen(!submenuOpen); // Toggle the submenu open/close
    };

    return (
        <div className={nav ? 'mobile-navbar open' : 'mobile-navbar'}>
            <h1 className="mobile-navbar-title">
                <Link to="/" onClick={closeSubmenus}>Stat<span className="navbar-span">Leap</span></Link>
            </h1>
            <ul className="mobile-navbar-links">
                <li className="mobile-navbar-link-item">
                    <Link to="/ml-tools" onClick={closeSubmenus}>ML Tools</Link>
                </li>
                <li className="mobile-navbar-link-item">
                    <Link to="/case-studies" onClick={closeSubmenus}>Case Studies</Link>
                </li>
                <li className="mobile-navbar-link-item">
                    <Link to="/resources" onClick={closeSubmenus}>Resources</Link>
                </li>
                <li className="mobile-navbar-link-item">
                    <Link to="/purchase-bundle" onClick={closeSubmenus}>Bundles</Link>
                </li>

                {/* Support Dropdown Submenu */}
                <li className="mobile-navbar-link-item">
                    <span onClick={toggleSubmenu} className="submenu-title">Support</span>
                    {submenuOpen && (
                        <ul className="mobile-dropdown">
                            <li className="mobile-dropdown-item">
                                <Link to="/support" onClick={closeSubmenus}>Contact Us</Link>
                            </li>
                            <li className="mobile-dropdown-item">
                                <Link to="/faq" onClick={closeSubmenus}>FAQ</Link>
                            </li>
                        </ul>
                    )}
                </li>

                {user && (userRole === 'admin' || userRole === 'instructor') && (
                    <li className="mobile-navbar-link-item">
                        <Link to="/instructor-dashboard" onClick={closeSubmenus}>Instructor</Link>
                    </li>
                )}

                {user && userRole === 'admin' && (
                    <li className="mobile-navbar-link-item">
                        <Link to="/admin" onClick={closeSubmenus}>Admin</Link>
                    </li>
                )}

                {user ? (
                    <>
                        <li className="mobile-navbar-link-item">
                            <Link to="/profile" onClick={closeSubmenus}>Profile</Link>
                        </li>
                        <li className="mobile-navbar-link-item">
                            <button onClick={handleLogout} className="whitespace-nowrap">Logout</button>
                        </li>
                    </>
                ) : (
                    <li className="mobile-navbar-link-item">
                        <Link to="/login" onClick={closeSubmenus}>Login / Register</Link>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default MobileNavbar;
